import { logout } from "@/utils/auth";
import FaUser from "@/components/SVG/Fontawesome/FaUser";
import LinkWrapper from "../LinkWrapper";
import { useDispatch, useSelector } from "react-redux";
import { resetOffCanvasView, updateOffCanvasView, updateOffCanvasViewPayload } from "@/store/slices/offCanvas";
import { selectUser } from "@/store/slices/auth";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const logoutClick = () => {
    logout();
    dispatch(updateOffCanvasViewPayload({ LOGIN: { verified: false } }))
    dispatch(resetOffCanvasView());
  }
  const isVip = user.vipData?.course.enabled;

  if (!user.id) {
    return <span className="hidden lg:flex justify-center items-center">
      <button name="login-btn" id="login-btn" type="button" className="flex justify-center items-end space-x-1" onClick={() => dispatch(updateOffCanvasView({ name: "LOGIN", data: { LOGIN: { method: "OTP" } } }))}>
        <span className="text-accent">Login</span>
      </button>
    </span>
  }

  return (
    <div className="dropdown hidden lg:inline-block relative">
      <button className="w-max py-6">
        <FaUser className="text-accent text-xl transition duration-150 ease-in-out" />
      </button>
      <ul className="dropdown-content absolute -left-16 hidden z-[1000] mt-0 rounded-md w-max border-none bg-secondary-light bg-clip-padding shadow-lg">
        {/* <li id="profile-btn" className="py-3 px-8 w-32 hover:text-primary">
          <LinkWrapper href="/profile" className="w-full text-left">
            Profile
          </LinkWrapper>
        </li>
        <hr /> */}
        {
          isVip && <li id="my-courses-btn" className="py-3 px-8 w-max hover:text-accent">
            <LinkWrapper href="/my-course" className="w-full text-left">
              My Course
            </LinkWrapper>
          </li>
        }
        <hr />
        {
          <li id="my-courses-btn" className="py-3 px-8 w-max hover:text-accent">
            <LinkWrapper href="/profile" className="w-full text-left">
              My Profile
            </LinkWrapper>
          </li>
        }
        <hr />
        <li id="logout-btn" className="py-3 px-8 w-32 hover:text-accent">
          <button onClick={logoutClick} className="w-full text-left">Logout</button>
        </li>
      </ul>
    </div>
  );
}

export default Profile;